import React from "react"

import { notFound } from "../assets/images/images"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import InnerPage from "../components/innerPage"

const NotFoundPage = () => (
  <InnerPage>
    <InnerHeader>
      <Breadcrumb label={'Page not found'} />
    </InnerHeader>
    <div className="textCenter py3 py5md pb140md textLg px3">
      <img src={notFound} alt="" className="mh100 mh300md" />
      <h3 className="textSecondary textMd textLgMd">Ne pare rau, pagina pe care incerci sa o accesezi nu exista!</h3>
    </div>
  </InnerPage>
)

export default NotFoundPage
